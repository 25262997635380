import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { LoggedInGuard } from './core/guards/loggedIn/logged-in.guard';
import { InitialDataResolver } from './app.resolver';

const MAIN = 'home'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    //home
    path: '',
    canActivate: [AuthGuard],
    component: FullLayoutComponent,
    data: { title: 'full Views' },
    resolve: {
      initialData: InitialDataResolver,
    },
    children: Full_ROUTES,

  },
  {
    // auth routes
    path: '',
    canActivate: [LoggedInGuard],
    component: ContentLayoutComponent,
    data: { title: 'content Views' },
    children: CONTENT_ROUTES,
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
