import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ColorSwitcherComponent } from './color-switcher/color-switcher.component';
import { CardsComponent } from './componentes/cards/cards.component';
import { LineChartComponent } from './componentes/line-chart/line-chart.component';
import { PieChartComponent } from './componentes/pie-chart/pie-chart.component';
import { BarChartComponent } from './componentes/bar-chart/bar-chart.component';
import { ChartsModule } from 'ng2-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import { CustomInputComponent } from './componentes/custom-input/custom-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BoxErrorsComponent } from './componentes/box-errors/box-errors.component';

@NgModule({
  exports: [
    CommonModule,
    CardsComponent,
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ColorSwitcherComponent,
    NgbModule,
    CustomInputComponent,
    BoxErrorsComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    PerfectScrollbarModule,
    HighchartsChartModule,
    ChartsModule,
    ReactiveFormsModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ColorSwitcherComponent,
    CardsComponent,
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
    CustomInputComponent,
    BoxErrorsComponent,
  ],
  providers: [],
})
export class SharedModule {}
