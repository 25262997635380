import {Component, OnInit, Renderer2} from '@angular/core';
import {ROUTES} from './sidebar-routes.config';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import {SidebarService} from "./sidebar.service";

import * as $ from 'jquery';
import {DynamicMenuSidebarService} from "@/services/dynamic-menu-sidebar.service";
import {RouteInfo} from './sidebar.metadata';
import {ReportMenuInterface} from "@/shared/interfaces/report-menu.interface";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

  public menuItems: RouteInfo[] = [];

  public isMini: boolean = false;


  constructor(
    public sidebarservice: SidebarService,
    private router: Router,
    private renderer: Renderer2,
    private menuService: DynamicMenuSidebarService
  ) {

    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd && $(window).width() < 1025 && (document.readyState == 'complete' || false)) {

        this.toggleSidebar();
        // Hide loading indicator

      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });

  }


  toggleSidebar() {
    this.isMini = !this.isMini;
    if (!this.isMini) {
      $('.page-wrapper').removeClass('mini-sidebar');
    } else {
      $('.page-wrapper').addClass('mini-sidebar');
    }

  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }


  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    $.getScript('./assets/js/app-sidebar.js');

    this.menuService.getMenu().subscribe(data => {
      if (data.results.length) {
        let index = this.menuItems.findIndex(x => x.key === 'reports');
        this.menuItems[index].submenu = [];
        data.results.forEach((item: ReportMenuInterface) => {
          this.menuItems[index].submenu.push({
            path: '/reports',
            queryParams: {url: item.url, title: item.title},
            title: item?.title,
            icon: 'bx bx-right-arrow-alt',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          });

        })

      }
    });

  }

  goToHome() {
    this.router.navigate(['/home']);
  }
}
