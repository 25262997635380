import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-box-errors',
  templateUrl: './box-errors.component.html',
  styleUrls: ['./box-errors.component.scss']
})
export class BoxErrorsComponent implements OnInit, OnChanges {

  @Input() errors: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    console.log(this.errors);

    this.cdr.detectChanges();

  }

  closeBox() {
    this.errors = [];
  }

}
