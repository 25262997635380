import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';

export var lineChartOptions: any = {
  animation: {
    duration: 1000, // general animation time
    easing: 'easeOutBack',
  },
  hover: {
    animationDuration: 1000, // duration of animations when hovering an item
    mode: 'label',
  },
  responsiveAnimationDuration: 1000, // animation duration after a resize
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    labels: {
      fontColor: '#585757',
      boxWidth: 40,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: '#585757',
          padding: 10,
        },
        display: true,
        gridLines: {
          color: 'rgba(0, 0, 0, 0.07)',
          drawTicks: false,
        },
        scaleLabel: {
          display: false,
          labelString: 'week',
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: '#585757',
          padding: 10,
        },
        display: true,
        gridLines: {
          color: 'rgba(0, 0, 0, 0.07)',
          drawTicks: false,
        },
        scaleLabel: {
          display: false,
          labelString: 'Value',
        },
      },
    ],
  },
};
export var lineChartColors: Array<any> = [
  {
    fill: true,
    backgroundColor: 'transparent',
    borderColor: '#0d6efd',
    pointRadius: '0',
    borderWidth: 4,
  },
  {
    fill: true,
    backgroundColor: 'transparent',
    borderColor: '#f41127',
    pointRadius: '0',
    borderWidth: 4,
  },
];

type ChartData = {
  data: number[];
  label: string;
};

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
  @Input() data: ChartData[] = [];
  @Input() labels: string[] = [];

  public lineChartData = this.data;
  public lineChartLabels = this.labels;
  public lineChartOptions = lineChartOptions;
  public lineChartColors = lineChartColors;
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';

  constructor() {}

  ngOnInit(): void {
    this.updateChartSettings();
  }

  updateChartSettings() {
    this.lineChartLabels = this.labels;
    this.lineChartData = this.data;
    this.lineChartColors = lineChartColors;
  }
}
