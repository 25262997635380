import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  @Input() labels: string[] = [];
  @Input() data: number[] = [];
  @Input() title: string = '';

  public pieChartLabels = this.labels;
  public pieChartData = this.data;
  public pieChartType: ChartType = 'pie';
  public pieChartColors = [
    {
      backgroundColor: ['#0d6efd', '#212529', '#17a00e', '#f41127', '#ffc107'],
    },
  ];
  public pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: this.title,
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.updateChartSettings();
  }

  updateChartSettings() {
    this.pieChartOptions.title.text = this.title;
    this.pieChartLabels = this.labels;
    this.pieChartData = this.data;
    this.pieChartColors = [
      {
        backgroundColor: [
          '#0d6efd',
          '#212529',
          '#17a00e',
          '#f41127',
          '#ffc107',
        ],
      },
    ];
  }
}
