import { AuthService } from '@/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivate(): Observable<boolean> {
    console.log('logged in guard called');
    return this._authService.check().pipe(
      switchMap((authenticated) => of(!authenticated))
  );
  }
}
