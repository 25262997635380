import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, throwError } from 'rxjs';
import { Params } from '@angular/router';
import { API_URL } from '@/shared/constants/route.constant';
import { ApiResponse, ApiResponseList } from '@/shared/models/api-responses.model';
import { UserModel } from '@/shared/models/user.model';
import { User } from '@/shared/interfaces/user-types.interface';
import { AuthUtils } from 'src/app/core/utils/auth.utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authenticated: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    // private _userService: UserService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  get isAuthenticated(): boolean {
    return !!this.accessToken
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.get(API_URL + '/users/v1/reset_password/' + email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string, _token: string): Observable<any> {
    return this._httpClient.put(API_URL + '/users/v1/change_password', {
      new_password: password
    }, {
      headers: {
        Authorization: `Bearer ${_token}`
      }

    });
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { username: any; password: any }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(`${API_URL}/users/v1/auth/?type=BACKOFFICE`, credentials).pipe(
      switchMap((response: any) => {

        // Store the access token in the local storage
        this.accessToken = response.access;
        this.refreshToken = response.refresh;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  autoSing(token: string): Observable<ApiResponse<any>> {
    return this._httpClient.get(`${API_URL}/users/v1/auto_sign/${token}`);
  }
  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient.post(`${API_URL}/users/v1/auth/refresh/`, {
      refresh: this.refreshToken
    }).pipe(
      catchError(() => {

        // Return false
        return of(false)
      }),
      switchMap((response: any) => {

        // Store the access token in the local storage
        this.accessToken = response.access;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return true
        return of(true);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: UserModel): Observable<User> {
    return this._httpClient.post<User>(API_URL + '/users/v1/users/', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post(API_URL + '/users/v1/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return this.signInUsingToken();
    }

    // If the access token exists and it didn't expire, sign in using it
    return of(true);
  }
}
