<div [formGroup]="parentForm" class="w-100">


  <div *ngIf="labelText.length" class="d-flex ion-justify-content-start ion-align-items-baseline"
    style="margin-bottom: 3px;">
    <label for="">{{ labelText }}</label>
    <button *ngIf="toolTip.length" class="btn-tooltip" container="body" placement="right" ngbTooltip="{{toolTip}}">
      <ion-icon style="font-size: 12px; height: 12px; width: 12px;" name="help-circle-outline"></ion-icon>
    </button>
  </div>

  <input [formControlName]="controlName" [readonly]="readonly" (change)="eventChange($event)"
    (input)="eventInput($event)" (keyup)="eventKeyUp($event)" (keyup.enter)="eventKeyEnter($event)"
    [ngClass]="{'is-invalid': (parentForm.controls[controlName].invalid  && parentForm.controls[controlName].touched) || ( customValidatorName.length ? parentForm.hasError(customValidatorName) : false)   }"
    [type]="typeInput" class="form-control" placeholder="{{placeholder}}">


  <div *ngIf="type === 'password'" class="password-toggle">
    <button (click)="toggleShowPassword()"><ion-icon color="secondary" style="font-size: 20px;"
        [name]="showPassword ? 'eye-outline': 'eye-off-outline' "></ion-icon></button>
  </div>



  <!-- Custom validator -->


  <div *ngIf="customValidatorName.length && parentForm.hasError(customValidatorName)" class="invalid-feedback">
    <span>{{ customValidatorMessage }}</span>
  </div>

  <div *ngIf="parentForm.controls[controlName].invalid
  && parentForm.controls[controlName].touched" class="invalid-feedback">

    <span *ngIf="parentForm.controls[controlName].errors?.['required']">El campo es requerido</span>

    <span *ngIf="parentForm.controls[controlName].errors?.['email']">El campo debe ser un email válido</span>

    <span *ngIf="parentForm.controls[controlName].errors?.['minlength']">El campo debe tener al menos {{ parentForm.controls[controlName].errors?.['minlength'].requiredLength }}
      caracteres</span>

    <span *ngIf="parentForm.controls[controlName].errors?.['maxlength']">El campo debe tener como máximo {{ parentForm.controls[controlName].errors?.['maxlength'].requiredLength }}
      caracteres</span>
    <!-- } @else if (parentForm.controls[controlName].errors?.['pattern']) {
    <span>El campo no cumple con el formato requerido</span>
    } @else if (parentForm.controls[controlName].errors?.['min']) {
    <span>El campo debe ser mayor o igual a {{ parentForm.controls[controlName].errors?.['min'].min }}</span>
    } @else if (parentForm.controls[controlName].errors?.['max']) {
    <span>El campo debe ser menor o igual a {{ parentForm.controls[controlName].errors?.['max'].max }}</span>
    } @else if (parentForm.controls[controlName].errors?.['passwordsNotEqual']) {
    <span>Las contraseñas no coinciden</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidDate']) {
    <span>La fecha no es válida</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidTime']) {
    <span>La hora no es válida</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidDateTime']) {
    <span>La fecha y hora no son válidas</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidDateRange']) {
    <span>El rango de fechas no es válido</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidTimeRange']) {
    <span>El rango de horas no es válido</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidDateTimeRange']) {
    <span>El rango de fechas y horas no es válido</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidDateRangeStart']) {
    <span>La fecha de inicio no puede ser mayor a la fecha de fin</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidTimeRangeStart']) {
    <span>La hora de inicio no puede ser mayor a la hora de fin</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidDateTimeRangeStart']) {
    <span>La fecha y hora de inicio no pueden ser mayores a la fecha y hora de fin</span>
    } @else if (parentForm.controls[controlName].errors?.['invalidDateRangeEnd']) {
    <span>La fecha de fin no puede ser menor a la fecha de inicio</span>
    } -->

  </div>

</div>
