import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit {
  @Input() title: string;
  @Input() titleIcon: string;

  @Input() body: string;
  @Input() footer?: string;
  @Input() imgPath?: string;

  @Input() redirect: string = '/default/';
  @Input() buttonText: string;
  @Input() buttonBackground: string = 'btn-primary';

  @Input() redirect2?: string;
  @Input() buttonText2?: string;
  @Input() buttonBackground2?: string;

  @Input() isHorizontal: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  redirectTo(redirect: string = this.redirect) {
    this.router.navigate([redirect]);
  }
}
