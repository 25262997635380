<div class="bg-login">
  <!--wrapper-->
  <div class="min-h-screen">
    <div class="flex flex-row place-content-center py-28 px-32">
      <div class="flex flex-col w-1/2">
        <h1 class="text-white text-6xl font-bold mt-28">
          ¡Bienvenido de nuevo!
        </h1>
        <p class="text-white text-3xl font-bold">
          Conéctate y vive la experiencia
        </p>
        <img class="mt-72" src="assets/images/logo-icon.png" width="170" />
      </div>
      <div class="card w-1/2">
        <div class="card-body">
          <div class="p-4 rounded">
            <div class="flex text-center flex-col">
              <h1 class="text-violet-400 text-6xl font-bold">
                Inicio de sesión
              </h1>
              <p class="text-stone-500 text-3xl font-normal my-16">
                Llene los siguientes datos para ingresar a la plataforma
              </p>
            </div>
            <div class="form-body">
              <app-box-errors [errors]="errors"></app-box-errors>
              <form [formGroup]="signInForm" class="row g-3">
                <div class="col-12">
                  <app-custom-input placeholder="Ingrese su usuario" [parentForm]="signInForm"
                    controlName="username"></app-custom-input>
                </div>
                <div class="col-12">
                  <div class="input-group">
                    <input [type]="typePassword" class="form-control border-end-0"
                      [ngClass]="{'is-invalid': (signInForm.controls['password'].invalid  && signInForm.controls['password'].touched)  }"
                      placeholder="Ingrese su contraseña" formControlName="password" />
                    <button (click)="toogleFieldPassword()" class="input-group-text bg-transparent"><i class="bx"
                        [ngClass]="{
                        'bx-hide': typePassword === 'password',
                        'bx-show': typePassword === 'text'
                      }"></i></button>
                    <div *ngIf="signInForm.controls['password'].invalid
  && signInForm.controls['password'].touched" class="invalid-feedback">

                      <span *ngIf="signInForm.controls['password'].errors?.['required']">El campo es requerido</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                    <label class="form-check-label" for="flexSwitchCheckChecked">Recordarme</label>
                  </div>
                </div>
                <div class="col-md-6 text-end">
                  <a href="JavaScript:;" (click)="onForgotpassword()">Olvidé contraseña</a>
                </div>
                <div class="col-12">
                  <div class="d-grid">
                    <button [disabled]="signInForm.invalid" (click)="signIn()" class="btn btn-primary mt-28">
                      Iniciar sesión
                    </button>

                    <button class="btn btn-primary mt-[20px]">
                      Crear cuenta
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
