import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';

export var barChartOptions: any = {
  scaleShowVerticalLines: false,
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
    labels: {
      fontColor: '#585757',
      boxWidth: 40,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: '#585757',
        },
        gridLines: {
          display: true,
          color: 'rgba(0, 0, 0, 0.07)',
        },
      },
    ],

    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: '#585757',
        },
        gridLines: {
          display: true,
          color: 'rgba(0, 0, 0, 0.07)',
        },
      },
    ],
  },
};

export var barChartColors: Array<any> = [
  {
    backgroundColor: '#0d6efd',
  },
  {
    backgroundColor: '#f41127',
  },
];

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  @Input() labels: string[] = [];
  @Input() data: number[] = [];
  @Input() dataLabel: string = '';
  @Input() isHorizontal: boolean = false;

  public barChartOptions = barChartOptions;
  public barChartLabels = this.labels;
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [];
  public barChartColors = barChartColors;

  constructor() {}

  ngOnInit(): void {
    this.updateChartSettings();
  }

  updateChartSettings() {
    this.barChartLabels = this.labels;
    this.barChartData = [
      { barPercentage: 1, data: this.data, label: this.dataLabel },
    ];
    this.barChartColors = barChartColors;
    this.barChartType = this.isHorizontal ? 'horizontalBar' : 'bar';
  }
}
