import { AuthService } from '@/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) { }

  canActivate(): Observable<boolean | UrlTree> {
    console.log('authguard called');
    return this._authService.check().pipe(
      switchMap((authenticated) => {

          // If the user is not authenticated...
          if ( !authenticated )
          {
              // Redirect to the sign-in page with a redirectUrl param
              const urlTree = this._router.parseUrl(`/auth/sign-in`);

              return of(urlTree);
          }

          // Allow the access
          return of(true);
      })
  );
  }
}
