import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@/services/auth/auth.service';
import { lastValueFrom, map, take } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  signInForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
  });

  errors: string[] = [];
  typePassword: string = 'password';
loading: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  // On Forgotpassword link click
  onForgotpassword() {
    this.router.navigate(['forgot-password'], {
      relativeTo: this.route.parent,
    });
  }

  // On Signup link click

  onSignup() {
    this.router.navigate(['sign-up'], { relativeTo: this.route.parent });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async signIn() {

    this.authService.signIn({
      username: this.signInForm.value.username,
      password: this.signInForm.value.password
    }).subscribe(
      (response) => {
        const redirectURL = this.route.snapshot.queryParamMap.get('redirectURL') || '/home';

        // Navigate to the redirect url
        this.router.navigateByUrl(redirectURL);
      },
      (error) => {

        this.errors = []
        this.errors.push('Las credenciales son inválidas')
        this.cdr.detectChanges();

      },
      () => {
        // this.loadingBar.complete();
      },
    )
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.signInForm.reset();
  }

  toogleFieldPassword() {
    if (this.typePassword === 'password') {
      this.typePassword = 'text';
    } else {
      this.typePassword = 'password';
    }
  }
}
