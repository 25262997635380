<div *ngIf="!isHorizontal || !imgPath; else horizontalCard">
  <div class="col">
    <div class="card border-2 border-cyan-400">
      <img [src]="imgPath" class="h-60" *ngIf="imgPath" />
      <div class="card-body flex flex-col items-center justify-center">
        <div class="flex items-center space-x-2">
          <i *ngIf="titleIcon" [class]="titleIcon"></i>
          <h5 class="text-black font-bold text-2xl">{{ title }}</h5>
        </div>
        <hr class="border-2 text-cyan-400 w-full my-2" />
        <p class="text-gray-500 text-lg text-center px-10 my-6">
          {{ body }}
        </p>
        <p *ngIf="footer" class="text-gray-800 text-lg">{{ footer }}</p>
        <a
          (click)="redirectTo()"
          [style.backgroundColor]="buttonBackground"
          class="btn btn-primary px-4 py-2 w-full font-bold"
          >{{ buttonText }}</a
        >
        <a
          *ngIf="buttonText2"
          (click)="redirectTo(redirect2)"
          [style.backgroundColor]="buttonBackground2"
          class="btn btn-primary px-4 py-2 mt-2 w-full font-bold"
          >{{ buttonText2 }}</a
        >
      </div>
    </div>
  </div>
</div>

<ng-template #horizontalCard>
  <div class="card flex rounded-[12px] flex-row max-w-5xl mx-auto">
    <img [src]="imgPath" class="h-60 rounded-l-[12px]" />

    <div class="card-body">
      <h5 class="text-black font-bold text-2xl pb-2">{{ title }}</h5>
      <p class="text-gray-500 text-lg">
        {{ body }}
      </p>
      <p *ngIf="footer" class="text-gray-800 text-lg pb-2">
        {{ footer }}
      </p>
      <a
        (click)="redirectTo()"
        [style.backgroundColor]="buttonBackground"
        class="btn btn-primary w-full text-black font-bold"
        >{{ buttonText }}</a
      >
    </div>
  </div>
</ng-template>
