<div class="bg-login">
  <!-- wrapper -->
  <div class="wrapper">
    <div
      class="authentication-forgot d-flex align-items-center justify-content-center"
    >
      <div class="card forgot-box">
        <div class="card-body">
          <div class="p-4 rounded border">
            <div class="text-center">
              <i
                class="bi bi-shield-exclamation"
                style="font-size: 80px; color: #a084e8"
              ></i>
            </div>
            <h4 class="mt-4 font-bold text-center text-6xl">
              ¿Olvidaste tu contraseña?
            </h4>
            <p class="text-stone-500 text-3xl text-center mt-20">
              Ten presente que la contraseña <br />
              existente es la misma que usas para <br />
              tu correo de Microsoft
            </p>
            <div class="flex justify-center items-center">
              <img class="mt-36" src="assets/images/logo-icon.png" width="42" />
            </div>

            <div class="d-grid gap-2 mt-5">
              <a
                href="javascript:;"
                (click)="onSignIn()"
                class="btn btn-light btn-lg"
                ><i class="bx bx-arrow-back me-1"></i>Volver a Login</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end wrapper -->
</div>
