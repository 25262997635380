<!-- Sidebar Content starts -->
<div class="sidebar sidebar-wrapper" [ngClass]="{'sidebar-mini':isMini}">
  <!-- Sidebar Header starts -->
  <div class="sidebar-header bg-black">
    <div>
      <img (click)="goToHome()"
        src="assets/images/logo-blank.png"
        class="logo-icon"
        alt="logo icon"
      />
    </div>
    <!-- <div class="toggle-icon ms-auto"><i  (click)="toggleSidebar()"  class='bx bx-arrow-to-left'></i>
            </div> -->
  </div>
  <div class="sidebar-close" (click)="toggleSidebar()">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" style="fill: rgba(233, 0, 100, 1);transform: ;msFilter:;"><path d="m4.431 12.822 13 9A1 1 0 0 0 19 21V3a1 1 0 0 0-1.569-.823l-13 9a1.003 1.003 0 0 0 0 1.645z"></path></svg> -->
      <i class='bx' [ngClass]="{
        'bxs-left-arrow': !isMini,
        'bxs-right-arrow': isMini
      }"></i>

  </div>
  <!-- Sidebar Header Ends -->
  <div class="sidebar-content flex flex-col h-full sidebar-collapse " [perfectScrollbar]>

    <ul class="navigation navigation-menu flex-grow" >
      <!-- First level menu -->
      <li
        *ngFor="let menuItem of menuItems"
        [ngClass]="[menuItem.class]"
        [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a
          class="first-level"
          [ngClass]="{'sub-mini':isMini}"
          routerLinkActive="active"
          [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
          *ngIf="!menuItem.isExternalLink; else externalLinkBlock"
        >
          <i class="sidebar-icon-1" [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{ menuItem.title }}</span>
          <span
            *ngIf="menuItem.badge != ''"
            [ngClass]="[menuItem.badgeClass]"
            >{{ menuItem.badge }}</span
          >
        </a>
        <ng-template #externalLinkBlock>
          <a [href]="[menuItem.path]" target="_blank">
            <i class="sidebar-icon-1" [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{ menuItem.title }}</span>
            <span
              *ngIf="menuItem.badge != ''"
              [ngClass]="[menuItem.badgeClass]"
              >{{ menuItem.badge }}</span
            >
          </a>
        </ng-template>
        <!-- Second level menu -->
        <ul class="menu-content level_two" *ngIf="menuItem.submenu.length > 0">
          <li
            *ngFor="let menuSubItem of menuItem.submenu"
            [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'"
            [ngClass]="[menuSubItem.class]"
          >
            <a
              [routerLink]="
                menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]
              "
              [queryParams]="menuSubItem.queryParams"
              *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock"
            >
              <i class="text-[24px]" [ngClass]="[menuSubItem.icon]"></i>
              <span class="sub-menu-title">{{ menuSubItem.title }}</span>
              <span
                *ngIf="menuSubItem.badge != ''"
                [ngClass]="[menuSubItem.badgeClass]"
                >{{ menuSubItem.badge }}</span
              >
            </a>
            <ng-template #externalSubLinkBlock>
              <a [href]="[menuSubItem.path]" target="_blank">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="sub-menu-title">{{ menuSubItem.title }}</span>
                <span
                  *ngIf="menuSubItem.badge != ''"
                  [ngClass]="[menuSubItem.badgeClass]"
                  >{{ menuSubItem.badge }}</span
                >
              </a>
            </ng-template>
            <!-- Third level menu -->
            <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
              <li
                *ngFor="let menuSubsubItem of menuSubItem.submenu"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                [ngClass]="[menuSubsubItem.class]"
              >
                <a
                  [routerLink]="[menuSubsubItem.path]"
                  *ngIf="
                    !menuSubsubItem.isExternalLink;
                    else externalSubSubLinkBlock
                  "
                >
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="sub-menu-title">{{ menuSubsubItem.title }}</span>
                  <span
                    *ngIf="menuSubsubItem.badge != ''"
                    [ngClass]="[menuSubsubItem.badgeClass]"
                    >{{ menuSubsubItem.badge }}</span
                  >
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a [href]="[menuSubsubItem.path]" target="_blank">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="sub-menu-title">{{
                      menuSubsubItem.title
                    }}</span>
                    <span
                      *ngIf="menuSubsubItem.badge != ''"
                      [ngClass]="[menuSubsubItem.badgeClass]"
                      >{{ menuSubsubItem.badge }}</span
                    >
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <div class="navigation ">
      <ul>
        <li>
          <a routerLink="/dashboard/contact-us" class="flex items-center">
            <i class="sidebar-icon-1" ngClass="bx bx-headphone"></i>
            <span class="menu-title">Contáctanos</span>
          </a>
        </li>
        <li>
          <a routerLink="/dashboard/sign-out" class="flex items-center">
            <i class="sidebar-icon-1" ngClass="bx bx-exit"></i>
            <span class="menu-title">Cerrar sesión</span>
          </a>
        </li>
      </ul>
      <div class="bg-white text-gray-700 p-3 my-6 menu-title">
        <h2 class="text-lg font-semibold mb-4 flex items-center text-[18px]">
          <svg
            class="fontawesomesvg h-5 w-5 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z"
            />
          </svg>
          Políticas
        </h2>
        <div class="grid grid-cols-2 gap-1 text-[12px] min-w-[368px]">
          <a class="hover:text-blue-600">Políticas cookies</a>
          <a class="hover:text-blue-600">Políticas Privacidad</a>
          <a class="hover:text-blue-600">Términos & condiciones</a>
          <a class="hover:text-blue-600">Términos legales</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Sidebar Content Ends -->
</div>
