import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] =
  [
  /*{
    path: '',
    title: 'Áreas principales',
    icon: 'bx bx-joystick',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '',
        title: 'Retos',
        icon: 'bx bx-right-arrow-alt',
        class: 'sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/default2',
            title: 'Centro de retos',
            icon: 'bx bx-right-arrow-alt bx bx-right-arrow-alt',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
          },
          {
            path: '/default2',
            title: 'Nuevo reto',
            icon: 'bx bx-right-arrow-alt',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
          },
          {
            path: '/default2',
            title: 'Asignación de reto',
            icon: 'bx bx-right-arrow-alt',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
          },
        ],
      },
      {
        path: '/default2',
        title: 'Capacitaciones',
        icon: 'bx bx-right-arrow-alt',
        class: 'sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/default2',
            title: 'Centro de capacitación',
            icon: 'bx bx-right-arrow-alt bx bx-right-arrow-alt',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
          },
          {
            path: '/default2',
            title: 'Nuevo capacitación',
            icon: 'bx bx-right-arrow-alt',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
          },
          {
            path: '/default2',
            title: 'Asignación de capacitación',
            icon: 'bx bx-right-arrow-alt',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
          },
        ],
      },
    ],
  },*/
  {
    path: '',
    title: 'Visualizar',
    icon: 'bx bx-show',
    class: 'sub',
    badge: '',
    key:'reports',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
     /* {
        path: '/default2',
        title: 'Repo',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/default2',
        title: 'Redenciones & pedidos',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },*/
    ],
  },
 /* {
    path: '',
    title: 'Parametrización',
    icon: 'bx bx-cog',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/default2',
        title: 'Configurar informes',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/default2',
        title: 'Ans Cliente',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Crear',
    icon: 'bx bx-edit',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/default2',
        title: 'Organigrama',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/default2',
        title: 'Permisos',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/default2',
        title: 'Usuarios',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },*/
];
