<div class="chart-container1">
  <canvas
    baseChart
    [data]="pieChartData"
    [labels]="pieChartLabels"
    [options]="pieChartOptions"
    [chartType]="pieChartType"
    [colors]="pieChartColors"
  ></canvas>
</div>
