import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiResponseList } from '../models/api-responses.model';
import {ReportMenuInterface} from "@/shared/interfaces/report-menu.interface";


@Injectable({
  providedIn: 'root'
})
export class DynamicMenuSidebarService {

  constructor(
    private http: HttpClient
  ) {
  }


  getMenu(): Observable<ApiResponseList<ReportMenuInterface>> {
    return this.http.get<ApiResponseList<ReportMenuInterface>>('/indicators/v1/indicators/?tenant_id=1')
  }
}
